import Error from 'next/error'

const Custom404 = () => {
  return (
    <div className="text-gray-800">
      <Error statusCode={404} />
    </div>
  )
}

export default Custom404
